import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import BackButton from '../components/ui/BackButton';
import Button from '../components/ui/Button';
import InputGroup from '../components/ui/InputGroup';
import InputSelect from '../components/ui/InputSelect';
import Label from '../components/ui/Label';
import StepperLayout from '../components/layouts/StepperLayout';
import InputSelectOption from '../components/ui/InputSelectOption';
import {
  getBillPresenceType,
  getHouseholdSize,
  setHouseholdSize,
} from '../utils/localStorage/localStorageFunctions';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import usePreloadPageData from '../hooks/usePreloadPageData';
import SEOLayout from '../components/layouts/SEOLayout';

const YourHouseholdSchema = Yup.object().shape({
  householdSize: Yup.string().required('Please specify the number of people'),
});

const YourHousehold = () => {
  const [formInitialValues] = useState(() => {
    const householdSizeLS = getHouseholdSize();

    return { householdSize: householdSizeLS?.toString() || '' };
  });

  const handleHouseholdSizeSubmit = (values: typeof formInitialValues) => {
    const householdSizeInt = parseInt(values.householdSize, 10);
    if (householdSizeInt) setHouseholdSize(householdSizeInt);

    const billPresenceType = getBillPresenceType();

    if (billPresenceType === 'paperBill') {
      navigateAndSetActiveStep(StepUrl.URL_ABOUT_YOUR_BILL);
      return;
    }

    if (billPresenceType === 'noBill') {
      navigateAndSetActiveStep(StepUrl.URL_LIFE_SAVING);
    }
  };

  const handleBack = () => {
    navigateAndSetActiveStep(StepUrl.URL_BILL_TYPE);
  };

  usePreloadPageData(
    StepUrl.URL_ABOUT_YOUR_BILL,
    StepUrl.URL_CONTROLLED_LOAD,
    StepUrl.URL_BILL_TYPE
  );

  return (
    <SEOLayout>
      <StepperLayout
        currentStage={1}
        currentStep={4}
        pageUrl={StepUrl.URL_YOUR_HOUSEHOLD}
      >
        <div className="group my-3">
          <Formik
            initialValues={formInitialValues}
            onSubmit={(values) => {
              handleHouseholdSizeSubmit(values);
            }}
            validationSchema={YourHouseholdSchema}
          >
            {(props) => (
              <Form>
                <h2 className="my-2">Your household</h2>
                <p className="sub-heading">
                  The size of your household will help us estimate the best
                  value plan for you.
                </p>
                <InputGroup className="my-2">
                  <Label htmlFor="inp-people" id="inp-people-label">
                    How many people live at home?
                  </Label>
                  <InputSelect
                    id="inp-people"
                    name="householdSize"
                    placeholder="Number of people"
                    aria-labelledby="inp-people-label inp-people"
                  >
                    <InputSelectOption value="1">1</InputSelectOption>
                    <InputSelectOption value="2">2</InputSelectOption>
                    <InputSelectOption value="3">3</InputSelectOption>
                    <InputSelectOption value="4">4</InputSelectOption>
                    <InputSelectOption value="5">5+</InputSelectOption>
                  </InputSelect>
                </InputGroup>
                <Button
                  className="mt-5"
                  type="submit"
                  disabled={props.values.householdSize === ''}
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <BackButton text="Back" onClick={handleBack} />
      </StepperLayout>
    </SEOLayout>
  );
};

export default YourHousehold;
